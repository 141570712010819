import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Footer from "./components/footer/Footer";
import companyLogo from "./assets/header-assets/logo4.svg";
import Event1Details from "./components/events/event_pages/event1";
import Event2Details from "./components/events/event_pages/event2";
import Event7Details from "./components/events/event_pages/event7";
import Event8Details from "./components/events/event_pages/event8";
import Event9Details from "./components/events/event_pages/event9";
import Event10Details from "./components/events/event_pages/event10";
import Event11Details from "./components/events/event_pages/event11";
import Event12Details from "./components/events/event_pages/event12";
import Event13Details from "./components/events/event_pages/event13";
import Event14Details from "./components/events/event_pages/event14";
import Event15Details from "./components/events/event_pages/event15";
import Event16Details from "./components/events/event_pages/event16";

import NewsLetter from "./pages/newsletter/newsletter";
import CoverPageHeader from "./components/cover_page_header/cover_page_header";
import CoverPage from "./pages/coverpage/coverpage";
import ListNews from "./pages/list_news/listnews";
import NewsInsider from "./pages/news_insider/news_insider";
import ListCatNews from "./pages/list_cat/list_cat";
import Editorial from "./pages/editorial/editorial";
import ImageFlipBook from "./pages/magazine_demo/magazine";
import About from "./pages/about/About";
import Events from "./pages/events/events";
import ContactUs from "./pages/contact/contact";
import TermsAndConditions from "./pages/terms/terms";
import PrivacyPolicy from "./pages/privacy/privacy";
import ChemicalTodayTV from "./pages/ct-tv/chemicaltodaytv";
import SubscribeForm from "./pages/subscribe/subscribe";
import Sustainability from "./pages/events/events/sustainability";
import ThankYouPage from "./components/thankyou/thankyou";
import AdvertiseWithUs from "./pages/advtwithus/advtwithus";
import ThankYouPage2 from "./components/thankyou/thankyou2";
import { isMobile, isTablet, isWinPhone } from "react-device-detect";
import SearchPage from "./pages/search/search";
import BasicExample from "./components/resp_header/resp_header";
import Event3Details from "./components/events/event_pages/event3";
import Event4Details from "./components/events/event_pages/event4";
import { Helmet } from "react-helmet";
import PdfViewer from "./pages/PdfViewer/PdfViewer";
import mediakit from "./assets/Chemical_Today_Media_kit.pdf";
import editorialcalender from "./assets/Chemical_Today_Editorial_Calendar.pdf";
import Event5Details from "./components/events/event_pages/event5";
import Event6Details from "./components/events/event_pages/event6";
import S3PdfViewer from "./pages/s3pdfviewer/s3pdfviewer";
import Pdf from "./pages/LetterView/pdf";

function App({ currentPath }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const excludeHeaderFooterRoutes = [
    "/events/sustainability",
    "/events/sustainability",
  ];
  const shouldExcludeHeaderFooter =
    excludeHeaderFooterRoutes.includes(currentPath);
  console.log(currentPath);

  return (
    <Router>
      <div className="noselect">
        {!shouldExcludeHeaderFooter &&
        (currentPath.startsWith("/magazine") ||
          currentPath.startsWith("/industrial_events/")) ? (
          screenWidth <= 1100 ? (
            <BasicExample />
          ) : (
            <BasicExample />
          )
        ) : screenWidth <= 1100 || isTablet || isMobile ? (
          <BasicExample />
        ) : (
          <CoverPageHeader />
        )}

        <Routes>
          <Route path="/editorial" element={<Editorial />} />
          <Route path="/newsletters" element={<NewsLetter />} />
          {/* ----newsletters change------ */}
          <Route path="/newsletters/:folder/:file" element={<Pdf />} />
          <Route
            path="/industrial_events/Global_Refining__and_Petrochemicals_Congress"
            element={<Event1Details />}
          />
          {/* -------- 14-06 added ---------- */}
          <Route
            path="/industrial_events/The_3rd_Asia_Green_Packaging_Innovation_Summit_2024"
            element={<Event12Details />}
          />
          <Route
            path="/industrial_events/CPHI_&_PMEC_India_2024"
            element={<Event13Details />}
          />
          <Route
            path="/industrial_events/RE_Source_2024"
            element={<Event14Details />}
          />
          <Route
            path="/industrial_events/The_2nd_European_Sustainable_Plastics_Summit_2024"
            element={<Event11Details />}
          />
          <Route
            path="/industrial_events/Industrial_Pollution_Control_Congress_2024"
            element={<Event10Details />}
          />
          <Route
            path="/industrial_events/SEPAWA_CONGRESS"
            element={<Event8Details />}
          />
          <Route path="/industrial_events/ADIPEC" element={<Event2Details />} />
          <Route
            path="/industrial_events/IBAAS_IIM_2024_Conference_&_Exhibition"
            element={<Event7Details />}
          />
          <Route
            path="/industrial_events/North_American_Biopolymer_Summit"
            element={<Event9Details />}
          />

          <Route
            path="/industrial_events/Reactor_World_Expo"
            element={<Event3Details />}
          />
          <Route
            path="/industrial_events/India_Chem_2024"
            element={<Event4Details />}
          />
          <Route
            path="/industrial_events/Turkchem_2024"
            element={<Event5Details />}
          />
          <Route
            path="/industrial_events/Drug_Safety_Symposium_2024"
            element={<Event6Details />}
          />
          <Route
            path="/industrial_events/6th_Annual_Pharma_Manufacturing_&_Automation_Convention_2024"
            element={<Event16Details />}
          />
          <Route
            path="/industrial_events/Pharma_Manufacturing_&_Automation_Excellence_Awards_2024"
            element={<Event15Details />}
          />

          <Route path="/magazine/:folderName" element={<ImageFlipBook />} />
          <Route path="/about" element={<About />} />
          <Route path="/subscribe/thank-you" element={<ThankYouPage />} />
          <Route path="/contact/thank-you" element={<ThankYouPage2 />} />

          <Route path="/advertise-with-us" element={<AdvertiseWithUs />} />

          <Route path="/" element={<CoverPage />} />
          <Route path="/:newsType/:pageNo" element={<ListNews />} />

          <Route path="/news/:newsType/:id/:title?" element={<NewsInsider />} />
          {/* <Route path="/news/:newsType/:id/:title?" component={<NewsInsider/>} /> */}

          <Route path="/:newsType/:cat/:pageNo" element={<ListCatNews />} />

          <Route path="/events" element={<Events />} />
          <Route path="/events/sustainability" element={<Sustainability />} />

          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy-and-poilicies" element={<PrivacyPolicy />} />

          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/chemical-today-tv" element={<ChemicalTodayTV />} />
          <Route path="/subscribe" element={<SubscribeForm />} />
          <Route path="/search" element={<SearchPage />} />
          <Route
            path="/magazine/:year/:folder/:file"
            element={<S3PdfViewer />}
          />
          <Route
            path="/pdf-viewer/media-kit"
            element={<PdfViewer file={mediakit} />}
          />
          <Route
            path="/pdf-viewer/editorial-calendar"
            element={<PdfViewer file={editorialcalender} />}
          />
        </Routes>
        {!shouldExcludeHeaderFooter && <Footer />}
      </div>
    </Router>
  );
}

export default App;

// import logo from "./logo.svg";
// import "./App.css";
// import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// import Footer from "./components/footer/Footer";
// import companyLogo from "./assets/header-assets/logo4.svg";
// import Event1Details from "./components/events/event_pages/event1";
// import Event2Details from "./components/events/event_pages/event2";
// import Event7Details from "./components/events/event_pages/event7";
// import Event8Details from "./components/events/event_pages/event8";
// import Event9Details from "./components/events/event_pages/event9";

// import CoverPageHeader from "./components/cover_page_header/cover_page_header";
// import CoverPage from "./pages/coverpage/coverpage";
// import ListNews from "./pages/list_news/listnews";
// import NewsInsider from "./pages/news_insider/news_insider";
// import ListCatNews from "./pages/list_cat/list_cat";
// import Editorial from "./pages/editorial/editorial";
// import ImageFlipBook from "./pages/magazine_demo/magazine";
// import About from "./pages/about/About";
// import Events from "./pages/events/events";
// import ContactUs from "./pages/contact/contact";
// import TermsAndConditions from "./pages/terms/terms";
// import PrivacyPolicy from "./pages/privacy/privacy";
// import ChemicalTodayTV from "./pages/ct-tv/chemicaltodaytv";
// import SubscribeForm from "./pages/subscribe/subscribe";
// import Sustainability from "./pages/events/events/sustainability";
// import ThankYouPage from "./components/thankyou/thankyou";
// import AdvertiseWithUs from "./pages/advtwithus/advtwithus";
// import ThankYouPage2 from "./components/thankyou/thankyou2";
// import { isMobile, isTablet } from "react-device-detect";
// import SearchPage from "./pages/search/search";
// import BasicExample from "./components/resp_header/resp_header";
// import Event3Details from "./components/events/event_pages/event3";
// import Event4Details from "./components/events/event_pages/event4";
// import { Helmet } from "react-helmet";
// import PdfViewer from "./pages/PdfViewer/PdfViewer";
// import mediakit from "./assets/Chemical_Today_Media_kit.pdf";
// import editorialcalender from "./assets/Chemical_Today_Editorial_Calendar.pdf";
// import Event5Details from "./components/events/event_pages/event5";
// import Event6Details from "./components/events/event_pages/event6";
// import S3PdfViewer from "./pages/s3pdfviewer/s3pdfviewer";

// function App({ currentPath }) {
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => {
//       setScreenWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);
//   const excludeHeaderFooterRoutes = [
//         "/events/sustainability",
//         "/events/sustainability",
//       ];
//       const shouldExcludeHeaderFooter =
//         excludeHeaderFooterRoutes.includes(currentPath);
//       console.log(currentPath);

//   const renderHeader = () => {
//     if (screenWidth <= 1100 || isMobile || isTablet) {
//       return <BasicExample />;
//     } else {
//       return <CoverPageHeader />;
//     }
//   };

//   return (
//     <div className="noselect">
//       {!shouldExcludeHeaderFooter && renderHeader()}
//       <Routes>
//         <Route path="/editorial" element={<Editorial />} />
//         <Route path="/industrial_events/Global_Refining__and_Petrochemicals_Congress" element={<Event1Details />} />
//         <Route path="/industrial_events/SEPAWA_CONGRESS" element={<Event8Details />} />
//         <Route path="/industrial_events/ADIPEC" element={<Event2Details />} />
//         <Route path="/industrial_events/IBAAS_IIM_2024_Conference_&_Exhibition" element={<Event7Details />} />
//         <Route path="/industrial_events/North_American_Biopolymer_Summit" element={<Event9Details />} />
//         <Route path="/industrial_events/Reactor_World_Expo" element={<Event3Details />} />
//         <Route path="/industrial_events/India_Chem_2024" element={<Event4Details />} />
//         <Route path="/industrial_events/Turkchem_2024" element={<Event5Details />} />
//         <Route path="/industrial_events/Drug_Safety_Symposium_2024" element={<Event6Details />} />
//         <Route path="/magazine/:folderName" element={<ImageFlipBook />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/subscribe/thank-you" element={<ThankYouPage />} />
//         <Route path="/contact/thank-you" element={<ThankYouPage2 />} />
//         <Route path="/advertise-with-us" element={<AdvertiseWithUs />} />
//         <Route path="/" element={<CoverPage />} />
//         <Route path="/:newsType/:pageNo" element={<ListNews />} />
//         <Route path="/news/:newsType/:id/:title?" element={<NewsInsider />} />
//         <Route path="/:newsType/:cat/:pageNo" element={<ListCatNews />} />
//         <Route path="/events" element={<Events />} />
//         <Route path="/events/sustainability" element={<Sustainability />} />
//         <Route path="/contact" element={<ContactUs />} />
//         <Route path="/privacy-and-poilicies" element={<PrivacyPolicy />} />
//         <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
//         <Route path="/chemical-today-tv" element={<ChemicalTodayTV />} />
//         <Route path="/subscribe" element={<SubscribeForm />} />
//         <Route path="/search" element={<SearchPage />} />
//         <Route path="/magazine/:year/:folder/:file" element={<S3PdfViewer />} />
//         <Route path="/pdf-viewer/media-kit" element={<PdfViewer file={mediakit} />} />
//         <Route path="/pdf-viewer/editorial-calendar" element={<PdfViewer file={editorialcalender} />} />
//       </Routes>
//       {!shouldExcludeHeaderFooter && <Footer />}
//     </div>
//   );
// }

// export default App;
